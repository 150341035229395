<template>
  <div class="download-app">
    <b-img right fluid src="/app/app-icon.png" />
    <h1>Download Our App</h1>
    <p>Cash Box has a great new app to make managing your accounts even easier!</p>
    <p>Look for Incuto in the
      <a
          href="http://url3732.cashbox.org.uk/ls/click?upn=Eycy5l8BqVJf4VOug6ltNRe1vCCVh0nSugXDgFGdpKHLL7CLjs6V2JA9cB0jYVoFmTlXlJ6j8Xrev4SABOlOmw-3D-3DDlJ5_DC4y3DdDCeEScwRgaDHqvch0HavTAIeWmfe2rgWAkoWKmWhkHJqzdyRiH5w45O6WmFXI92s1uExE-2BbJzv37busTtqUz9KMU3o97CBtEj93THzCv5Sof-2FmgXCa2kkN5NHZHcdSYAJn-2BbP-2F-2FG9zZzPBEUbQWPnswr5748SnZLhALLvpKjnUV-2F6befHPeTAHc4W7OVDJr61elNv8x5tEdtu8j5ApiP5IqnWntXXuHQaeqpg4VvOPW5tz2JtPhrZPwXq5cg3QRPMymyvtIFi6DS2hxUEpRzDi8PGuFiSfWmfBVf47xCNx5X4F78Mv-2FPcNXvTWagUBBFFoYMrfepod0Hz-2Fg3Hvh0mn9xYW-2BiUijP3LubumaEo5TNyMAYiUybBo3rXlUDHsstLDWOr-2BNBzvjtrYBJ1UKautWCZcb6P29-2FHvcQurCb3uXpIFnbeF94hjLLCxy4DmiDRy6BEaWm1NC-2FlmamfTO6ygKI66PXeVYjiTHg-3D"
          target="_blank"
      >Apple App Store</a
      >
      or
      <a
          href="http://url3732.cashbox.org.uk/ls/click?upn=Eycy5l8BqVJf4VOug6ltNcAS40fyL2DHzVr9ePle5AKveElbya4Jn6tsCkNk-2FYDXL6WwA7sKUVGOIBPTyIygoJUVvUGqa56H4ECm2n1Gi-2FU-3DeBbm_DC4y3DdDCeEScwRgaDHqvch0HavTAIeWmfe2rgWAkoWKmWhkHJqzdyRiH5w45O6WmFXI92s1uExE-2BbJzv37busTtqUz9KMU3o97CBtEj93THzCv5Sof-2FmgXCa2kkN5NHZHcdSYAJn-2BbP-2F-2FG9zZzPBEUbQWPnswr5748SnZLhALLvpKjnUV-2F6befHPeTAHc4W7OVDJr61elNv8x5tEdtu8j5ApiP5IqnWntXXuHQaeqpg4VvOPW5tz2JtPhrZPwXq5cg3QRPMymyvtIFi6DS2hwb-2FQNQ4RB19jirRvm80E3IUVKxLWQTP-2F4Viyp-2BCX4CWqPC-2FY4IAR9A-2Bzb-2B4IbV0kCRt1HN-2Bl5-2BbVGEbotylHX5cDT-2F-2Fzo054QCSTufd3oWdhVetO8HrQMwuaQshxPX1Jzhtf6ghFZIPP1PuSrdPQsocHiGmVkUOqrUGVveWBHSUsHJtFQJ2l4byagw0JRNlAO2Ule1fxwCZzs4klwxZ-2BsQ-3D"
          target="_blank"
      >Google Play Store</a
      >
    </p>

    <p>Just download the app and sign in using your usual logging on details.</p>
    <p>You can check your balance, apply for a loan, request a withdrawal and send us a message.</p>

    <b-row class="py-3">
      <b-col col="6" sm="4">
        <a
            href="http://url3732.cashbox.org.uk/ls/click?upn=Eycy5l8BqVJf4VOug6ltNRe1vCCVh0nSugXDgFGdpKHLL7CLjs6V2JA9cB0jYVoFmTlXlJ6j8Xrev4SABOlOmw-3D-3DDlJ5_DC4y3DdDCeEScwRgaDHqvch0HavTAIeWmfe2rgWAkoWKmWhkHJqzdyRiH5w45O6WmFXI92s1uExE-2BbJzv37busTtqUz9KMU3o97CBtEj93THzCv5Sof-2FmgXCa2kkN5NHZHcdSYAJn-2BbP-2F-2FG9zZzPBEUbQWPnswr5748SnZLhALLvpKjnUV-2F6befHPeTAHc4W7OVDJr61elNv8x5tEdtu8j5ApiP5IqnWntXXuHQaeqpg4VvOPW5tz2JtPhrZPwXq5cg3QRPMymyvtIFi6DS2hxUEpRzDi8PGuFiSfWmfBVf47xCNx5X4F78Mv-2FPcNXvTWagUBBFFoYMrfepod0Hz-2Fg3Hvh0mn9xYW-2BiUijP3LubumaEo5TNyMAYiUybBo3rXlUDHsstLDWOr-2BNBzvjtrYBJ1UKautWCZcb6P29-2FHvcQurCb3uXpIFnbeF94hjLLCxy4DmiDRy6BEaWm1NC-2FlmamfTO6ygKI66PXeVYjiTHg-3D"
            target="_blank"
        >
          <b-img fluid src="/app/download-ios.png"/>
        </a>
      </b-col>
      <b-col col="6" sm="4">
        <a
            href="http://url3732.cashbox.org.uk/ls/click?upn=Eycy5l8BqVJf4VOug6ltNcAS40fyL2DHzVr9ePle5AKveElbya4Jn6tsCkNk-2FYDXL6WwA7sKUVGOIBPTyIygoJUVvUGqa56H4ECm2n1Gi-2FU-3DeBbm_DC4y3DdDCeEScwRgaDHqvch0HavTAIeWmfe2rgWAkoWKmWhkHJqzdyRiH5w45O6WmFXI92s1uExE-2BbJzv37busTtqUz9KMU3o97CBtEj93THzCv5Sof-2FmgXCa2kkN5NHZHcdSYAJn-2BbP-2F-2FG9zZzPBEUbQWPnswr5748SnZLhALLvpKjnUV-2F6befHPeTAHc4W7OVDJr61elNv8x5tEdtu8j5ApiP5IqnWntXXuHQaeqpg4VvOPW5tz2JtPhrZPwXq5cg3QRPMymyvtIFi6DS2hwb-2FQNQ4RB19jirRvm80E3IUVKxLWQTP-2F4Viyp-2BCX4CWqPC-2FY4IAR9A-2Bzb-2B4IbV0kCRt1HN-2Bl5-2BbVGEbotylHX5cDT-2F-2Fzo054QCSTufd3oWdhVetO8HrQMwuaQshxPX1Jzhtf6ghFZIPP1PuSrdPQsocHiGmVkUOqrUGVveWBHSUsHJtFQJ2l4byagw0JRNlAO2Ule1fxwCZzs4klwxZ-2BsQ-3D"
            target="_blank"
        >
          <b-img fluid src="/app/download-android.png" />
        </a>
      </b-col>
    </b-row>

    <hr/>

    <b-row class="my-3">
      <b-col cols="6" sm="4" md="4" lg="2">
        <b-img class="mb-2" fluid src="/app/app-screen-01.png" />
      </b-col>
      <b-col cols="6" sm="4" md="4" lg="2">
        <b-img class="mb-2" fluid src="/app/app-screen-02.png" />
      </b-col>
      <b-col cols="6" sm="4" md="4" lg="2">
        <b-img class="mb-2" fluid src="/app/app-screen-03.jpg" />
      </b-col>
      <b-col cols="6" sm="4" md="4" lg="2">
        <b-img class="mb-2" fluid src="/app/app-screen-04.jpg" />
      </b-col>
      <b-col cols="6" sm="4" md="4" lg="2">
        <b-img class="mb-2" fluid src="/app/app-screen-05.jpg" />
      </b-col>
      <b-col cols="6" sm="4" md="4" lg="2">
        <b-img class="mb-2" fluid src="/app/app-screen-06.jpg" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: 'DownloadApp',
  metaInfo() {
    return {
      title: 'Download Our App | Incuto | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
